<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <!-- <pre>{{ warehouses }}</pre> -->
    <v-row>
      <v-container>
        <v-row
          justify="center"
        >
          <v-col
            v-for="(item, i) in transactions"
            :key="i"
            cols="12"
            sm="6"
            lg="4"
          >
            <base-material-stats-card
              class="toggle-item"
              :color="item.color"
              :icon="item.icon"
              title="Transaccion"
              :top="item.title"
              :to="item.to"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-col
        cols="12"
      >
        <base-material-card
          color="warning"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Inventario
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Inventario disponible
            </div>

            <v-row
              justify="end"
            >
              <v-col cols="auto">
                <v-dialog
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      elevation="0"
                      class="ma-2 white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Reporte &nbsp;
                      <v-icon
                        right
                        dark
                        size="25"
                      >
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:default="dialog">
                    <v-card>
                      <v-toolbar
                        color="primary"
                        dark
                      >
                        <h3>
                          Seleccione el campo del inventario que le gustraia reportar
                        </h3>
                      </v-toolbar>
                      <br><br>
                      <v-card-text>
                        <v-row
                          justify="center"
                        >
                          <v-autocomplete
                            v-model="typeReport"
                            :items="typesOfInventory"
                            outlined
                            label="Campos de inventario"
                          />
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          color="warning"
                          @click="genPdf"
                        >
                          Generar
                        </v-btn>
                        <v-btn
                          text
                          @click="dialog.value = false"
                        >
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
              </v-col>
              <!-- <v-btn
                color="warning"
                elevation="0"
                class="ma-2 white--text"
                @click="loader = 'loading3'"
              >
                Imprimir reporte &nbsp;
                <v-icon
                  right
                  dark
                  size="25"
                >
                  mdi-printer
                </v-icon>
              </v-btn> -->
            </v-row>
          </template>

          <!-- <pre>{{ inventory }}</pre> -->

          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="inventory"
            :loading="fechingStoreInventory"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [
                100,
                -1
              ],
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <!-- <template v-slot:[`item.total`]="{ item }">
              {{ item.quantity_total }} {{ item.salesUnit }} / {{ item.quantity_packing_total }} {{ item.packingUnit }}
              {{ item.quantity_packing_total }} / {{ item.quantity_total }}
            </template> -->
            <template v-slot:[`item.available`]="{ item }">
              {{ item.quantity_packing_available }} / {{ item.quantity_available }}
            </template>

            <template v-slot:[`item.amount_total`]="{ item }">
              <!-- {{ item.quantity_total }} {{ item.salesUnit }} / {{ item.quantity_packing_total }} {{ item.packingUnit }} -->
              <!-- {{ item.quantity_packing_total }} / {{ item.quantity_total }} -->
              {{ item.amount_total | formatPrice }}
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <!-- {{ item.quantity_total }} {{ item.salesUnit }} / {{ item.quantity_packing_total }} {{ item.packingUnit }} -->
              <!-- {{ item.quantity_packing_total }} / {{ item.quantity_total }} -->
              {{ item.amount_total * item.quantity_total | formatPrice }}
            </template>
            <template v-slot:[`item.reserved`]="{ item }">
              {{ item.quantity_packing_reserved }} / {{ item.quantity_reserved }}
            </template>
            <template v-slot:[`item.units`]="{ item }">
              {{ item.packingUnit }} / {{ item.salesUnit }}
            </template>

            <template slot="body.append">
              <tr class="warning--text">
                <th
                  class="text-center text-h4"
                >
                  Totales
                </th>
                <!-- <th /> -->
                <th />
                <th
                  v-for="(item, i) in totalOfInventory"
                  :key="i"
                  class="text-center text-h4"
                >
                  <span v-if="i === totalOfInventory.length - 1">
                    {{ item | formatPrice }}
                  </span>

                  <span v-else>{{ item }}</span>
                </th>
              </tr>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>

      <!-- <v-col
        cols="12"
      >
        <v-skeleton-loader
          v-if="gettingBusinessChartData"
          :boilerplate="true"
          elevation="1"
          type="image"
        />
        <base-material-chart-card
          v-else
          :data="dailySalesChart.data"
          :options="dailySalesChart.options"
          color="success"
          hover-reveal
          type="Line"
        >
          <template v-slot:reveal-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  color="info"
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="info"
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </template>

              <span>Refresh</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  light
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>

              <span>Change Date</span>
            </v-tooltip>
          </template>

          <h4 class="card-title font-weight-light mt-2 ml-2">
            Presupuestos
          </h4>

          <p class="d-inline-flex font-weight-light ml-2 mt-1">
            1000$
          </p>
        </base-material-chart-card>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  // import pageCondicionerMixin from '@/mixins/pageCondicionerMixin'
  // import { ADMIN, USER, CLIENT } from '@/const'
  import { SUPER_ADMIN, GENERAL_MANAGER, ADMIN, STORE_MANAGER, SELLER } from '@/const'
  import { mapMutations, mapState, mapActions } from 'vuex'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'
  export default {
    name: 'BusinessDahboardView',

    // mixins: [pageCondicionerMixin([ADMIN, USER, CLIENT])],

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        typeReport: 'available',

        typesOfInventory: [
          {
            sortable: false,
            text: 'Reservado',
            value: 'reserved',
          },
          {
            sortable: false,
            text: 'Disponible',
            value: 'available',
          },
          {
            sortable: false,
            text: 'Total',
            value: 'total',
          },
        ],

        search: '',

        headers: [
          {
            sortable: false,
            text: 'Código',
            value: 'codigo',
            align: 'center',

          },
          {
            sortable: false,
            text: 'Producto',
            value: 'description',
            align: 'center',
          },
          // {
          //   sortable: false,
          //   text: 'Empaque / Unidad',
          //   value: 'units',
          //   align: 'center',
          // },
          {
            sortable: false,
            text: 'Rollos Disponible',
            value: 'quantity_packing_available',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Kg Disponible',
            value: 'quantity_available',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Rollos Reservados',
            value: 'quantity_packing_reserved',
            align: 'center',
          },
          {
            sortable: false,
            text: 'KG Reservados',
            value: 'quantity_reserved',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Rollos Total',
            value: 'quantity_packing_total',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Kg Total',
            value: 'quantity_total',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Precio Unitario',
            value: 'amount_total',
            align: 'center',
          },
          {
            sortable: false,
            text: 'Precio Total',
            value: 'total',
            align: 'center',
          },
        ],
        dailySalesChart: {
          data: {
            labels: [0, 0, 0, 0, 0, 0, 0],
            series: [
              [0, 0, 0, 0, 0, 0, 0],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            // high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: [0, 0, 0, 0, 0, 0, 0],
            series: [
              [0, 0, 0, 0, 0, 0, 0],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: [0, 0, 0, 0, 0, 0, 0],
            series: [
              [0, 0, 0, 0, 0, 0, 0],
            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
        items: [
          {
            id: 1,
            name: 'Dakota Rice',
            country: 'Niger',
            city: 'Oud-Tunrhout',
            salary: '$35,738',
          },
          {
            id: 2,
            name: 'Minerva Hooper',
            country: 'Curaçao',
            city: 'Sinaai-Waas',
            salary: '$23,738',
          },
          {
            id: 3,
            name: 'Sage Rodriguez',
            country: 'Netherlands',
            city: 'Overland Park',
            salary: '$56,142',
          },
          {
            id: 4,
            name: 'Philip Chanley',
            country: 'Korea, South',
            city: 'Gloucester',
            salary: '$38,735',
          },
          {
            id: 5,
            name: 'Doris Greene',
            country: 'Malawi',
            city: 'Feldkirchen in Kārnten',
            salary: '$63,542',
          },
        ],
        tabs: 0,
        tasks: {
          0: [
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: false,
            },
            {
              text: 'Create 4 Invisible User Experiences you Never Knew About',
              value: true,
            },
          ],
          1: [
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: false,
            },
          ],
          2: [
            {
              text: 'Lines From Great Russian Literature? Or E-mails From My Boss?',
              value: false,
            },
            {
              text: 'Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit',
              value: true,
            },
            {
              text: 'Sign contract for "What are conference organizers afraid of?"',
              value: true,
            },
          ],
        },
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },

    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'warehouses',
        'fechingStoreInventory',
        'storeInventory',
        'gettingBusinessChartData',
        'businessChartData',
        'actionsBusiness',
        'actionEnvironmentData',
      ]),

      inventory () {
        const result = this.storeInventory.filter(item => item.quantity_packing_total > 0 && item.quantity_total > 0)
        return result
      },

      totalOfInventory () {
        let re = 0
        let ru = 0
        let de = 0
        let du = 0
        let te = 0
        let tu = 0
        const au = ''
        let ta = 0

        this.inventory.forEach(item => {
          // // // // // // // console.log('PASOOOOO', item)
          re += item.quantity_packing_reserved
          ru += item.quantity_reserved

          de += item.quantity_packing_available
          du += item.quantity_available

          te += item.quantity_packing_total
          tu += item.quantity_total

          ta += item.amount_total * item.quantity_total
        })

        return [de, du, re, ru, te, tu, au, ta]
        // return []
      },

      isAdministrable () {
        return this.is(SUPER_ADMIN) || this.is(GENERAL_MANAGER) || this.is(ADMIN) || this.is(STORE_MANAGER)
      },

      isSeller () {
        return this.is(SELLER)
      },

      transactions () {
        const transactionsAdm = [
          // {
          //   icon: 'mdi-cart-arrow-down',
          //   title: 'Solicitar Despachos',
          //   to: '/admin/business/concession-request',
          //   color: 'info',
          // },
          // {
          //   icon: 'mdi-stamper',
          //   title: 'Aprobar unidad',
          //   to: '/admin/business/sales-no-approvate',
          //   color: 'error',
          // },
          {
            icon: 'mdi-currency-usd',
            title: 'Presupuestos',
            // to: '/admin/business/sales',
            to: '/adminis/business/new-sale/' + Number(this.$route.params.id),
            color: 'green',
          },
          {
            icon: 'mdi-archive-arrow-down-outline',
            title: 'Devoluciones',
            to: '/adminis/business/returns/' + Number(this.$route.params.id),
            color: 'grey',
          },
          {
            icon: 'mdi-newspaper-variant',
            title: 'Reportes',
            to: '/adminis/business/reports/' + Number(this.$route.params.id),
            color: 'teal',
          },
        ]

        const transactionsSel = [
          {
            icon: 'mdi-currency-usd',
            title: 'Presupuestos',
            to: '/adminis/business/new-sale/' + Number(this.$route.params.id),
            color: 'secondary',
          },
          {
            icon: 'mdi-newspaper-variant',
            title: 'Reportes',
            to: '/adminis/business/reports/' + Number(this.$route.params.id),
            color: 'teal',
          },
        ]

        if (this.user.showWarehouseInventoryId) {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('JEJE PUEDO VER EL ALMACEN ID:', this.user.showWarehouseInventoryId, '/admin/business/pricipal-inventory/' + this.user.showWarehouseInventoryId)
          transactionsSel.push(
            {
              icon: 'mdi-warehouse',
              title: 'Ver almacen principal',
              to: '/adminis/business/pricipal-inventory/' + this.user.showWarehouseInventoryId,
              color: 'info',
            },
          )

          transactionsAdm.push(
            {
              icon: 'mdi-warehouse',
              title: 'Ver almacen principal',
              to: '/admin/business/pricipal-inventory/' + this.user.showWarehouseInventoryId,
              color: 'info',
            },
          )
        }

        return this.isAdministrable ? transactionsAdm : transactionsSel
      },
    },

    watch: {
      async warehouses (v) {
        // // // // console.log('almacen de la tienda', v[0])
        this.setActionWarehouse(v[0].warehouseId)
        await this.fetchStoreInventory({ id: v[0].warehouseId })
        await this.getBusinessChartData({ warehouseId: v[0].warehouseId, businessId: this.actionsBusiness })
      },
      businessChartData (businessChartData) {
        if (businessChartData !== undefined) {
          this.dailySalesChart.data.labels = businessChartData
            .result
            .days
          this.dailySalesChart.data.series = [
            businessChartData.result.transactionCount,
          ]
        }
      },
    },

    async created () {
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('business')
      await this.getWarehousesByParentId({ id: this.$route.params.id })
    },

    methods: {
      ...mapActions([
        'getWarehousesByParentId',
        'fetchStoreInventory',
        'getBusinessChartData',
      ]),
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_BUSINESS',
        setActionWarehouse: 'SET_ACTIONS_WAREHOUSE',
      }),

      formatPriceF (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      getTotal (products) {
        let total = 0
        products.forEach(product => {
          total = total + (product.amount_total * product[`quantity_${this.typeReport}`])
        })

        return total
      },

      is (rol) {
        return this.user?.authorization.filter(auth => auth.roleId === rol).length > 0 || false
      },

      complete (index) {
        this.list[index] = !this.list[index]
      },

      async genPdf () {
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        doc.setFontSize(38).text('Sistema Genérico', 10, 23)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text(`Reporte de inventario ${this.typeReport === 'available' ? 'disponible' : this.typeReport === 'total' ? 'total' : 'reservado'} de la sucursal ${this.actionEnvironmentData.name} del ${this.formatDate(new Date())}`, doc.internal.pageSize.width / 2, 40, { align: 'center' })

        doc.autoTable({
          styles: { halign: 'center' },
          columns: [
            { title: 'Código', dataKey: 'codigo' },
            { title: 'Producto', dataKey: 'description' },
            // { title: 'Empaque / Unidad', dataKey: 'units' },
            { title: 'Rollos', dataKey: 'packingCant' },
            { title: 'KG', dataKey: 'cant' },
            { title: 'Precio Unitario', dataKey: 'amount_total' },
            { title: 'Precio Total', dataKey: 'total' },
          ],
          body: this.inventory.filter(item => item[`quantity_${this.typeReport}`]).map(item => {
            return item.quantity_total ? {
              ...item,
              packingCant: item[`quantity_packing_${this.typeReport}`],
              cant: item[`quantity_${this.typeReport}`],
              units: `${item.packingUnit} / ${item.salesUnit}`,
              total: this.formatPriceF(item.amount_total * item[`quantity_${this.typeReport}`]),
              amount_total: this.formatPriceF(item.amount_total),
            } : ''
          }),
          margin: {
            left: 10,
            top: 50,
          },
        })

        doc.setLineWidth(0.5).line(doc.internal.pageSize.width / 3, doc.internal.pageSize.height - 30, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 30)

        doc.setFontSize(10).text('Totales', doc.internal.pageSize.width / 3 + 10, doc.internal.pageSize.height - 25)
        doc.setFontSize(10).text(`${this.totalOfInventory[this.typeReport === 'available' ? 2 : this.typeReport === 'total' ? 4 : 0]}`, doc.internal.pageSize.width / 3 + 33, doc.internal.pageSize.height - 25, { align: 'center' })
        doc.setFontSize(10).text(`${this.totalOfInventory[this.typeReport === 'available' ? 3 : this.typeReport === 'total' ? 5 : 1]}`, doc.internal.pageSize.width / 3 + 51, doc.internal.pageSize.height - 25, { align: 'center' })
        doc.setFontSize(10).text(`${this.formatPriceF(this.getTotal(this.inventory))}`, doc.internal.pageSize.width - doc.internal.pageSize.width / 2 + 79, doc.internal.pageSize.height - 25, { align: 'center' })

        const pages = doc.internal.getNumberOfPages()
        const pageWidth = doc.internal.pageSize.width
        const pageHeight = doc.internal.pageSize.height
        doc.setFontSize(10)

        for (let j = 1; j < pages + 1; j++) {
          const horizontalPos = pageWidth - pageWidth / 10
          const verticalPos = pageHeight - 10
          doc.setPage(j)
          doc.text(`${j} de ${pages}`, horizontalPos, verticalPos, { align: 'center' })
        }

        doc.autoPrint()
        doc.output('dataurlnewwindow')
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },
    },
  }
</script>

<style scoped>
.toggle-item {
  transform: scale(1);
  transition: 200ms all ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select:none;
}

.toggle-item:hover {
  transform: scale(1.05);
}

.toggle-item:active {
  transform: scale(1);
}
</style>
